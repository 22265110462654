import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PropTypes from "prop-types";
import { ParticipantUpdate } from "./ParticipantUpdate";
import { Pagination, Stack } from "@mui/material";
import usePermission from "hooks/usePermission";
import { fetchWrapper } from "fetchWrapper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ParticipantList({
  handleParticipantCreate,
  handleParticipantList = [], // Asegúrate de tener un valor predeterminado
  handleopenNewUpload,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
  callHandleClickSubmit,
  handleAlert = () => {},
  requestParticipantList = () => {},
}) {
  // Verificar permisos
  const canCreate = usePermission("Crear", "Participantes");
  const canEdit = usePermission("Editar", "Participantes");
  const canDelete = usePermission("Eliminar", "Participantes");
  const canListEvidence = usePermission("Listar", "Evidencias");

  const [modalOpen, setModalOpen] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [participants, setParticipants] = useState(handleParticipantList);

  useEffect(() => {
    setParticipants(handleParticipantList);
  }, [handleParticipantList]);

  const handleOpenModal = (participant) => {
    setCurrentParticipant(participant);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentParticipant(null);
  };

  const updateParticipantInBackend = async (updatedParticipant, id) => {
    try {
      if (!id) {
        throw new Error("ID del participante no proporcionado.");
      }

      const response = await fetchWrapper(
        `${process.env.REACT_APP_BACKEND_URL}/participants/update/${id}/`,
        {
          method: "PUT",
          body: JSON.stringify(updatedParticipant),
        }
      );

      // if (response.ok) {
      // const result = await response.json();
      return response;
      // } else {
      //   const errorText = await response.text();
      //   throw new Error(`Error del servidor al realizar la solicitud: ${errorText}`);
      // }
    } catch (error) {
      const message = "Error del servidor al realizar la solicitud";
      return { success: false, message: error.message || message };
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Fecha inválida" : date.toISOString().slice(0, 10);
  };

  const handleUpdateParticipant = async (updatedParticipant) => {
    try {
      if (currentParticipant) {
        const response = await updateParticipantInBackend(
          updatedParticipant,
          currentParticipant.id
        );
        if (response.success) {
          // const updatedParticipants = participants.map((participant) =>
          //   participant.id === result.result.id ? result.result : participant
          // );
          // console.log("se actualizo el participante", updatedParticipants);
          // setParticipants(updatedParticipants); // Actualiza el estado local
          // handleParticipantList(updatedParticipants); // Actualiza el estado en el componente padre
          requestParticipantList(); // Actualiza la lista de participantes
          handleCloseModal(); // Cierra el modal después de una actualización exitosa
        } else {
          handleAlert("fail", response.message); // Muestra el mensaje de error al usuario
          // alert(result.message); // Muestra el mensaje de error al usuario
        } // Muestra el mensaje de error al usuario
        // alert(result.message); // Muestra el mensaje de error al usuario
      } else {
        handleAlert("fail", "Participante no proporcionado."); // Muestra el mensaje de error al usuario
      }
    } catch (error) {
      // console.error("Error al actualizar el participante:", error);
      handleAlert("fail", error.message);
    }
    // .then((result) => {
    //   if (result.success) {
    //     const updatedParticipants = participants.map((participant) =>
    //       participant.id === result.result.id ? result.result : participant
    //     );
    //     console.log("se actualizo el participante", updatedParticipants);
    //     setParticipants(updatedParticipants); // Actualiza el estado local
    //     handleParticipantList(updatedParticipants); // Actualiza el estado en el componente padre
    //     handleCloseModal(); // Cierra el modal después de una actualización exitosa
    //   } else {
    //     handleAlert(result.message); // Muestra el mensaje de error al usuario
    //     // alert(result.message); // Muestra el mensaje de error al usuario
    //   }
    // })
    // .catch((error) => {});
    // }
  };

  const columns = [
    { Header: "Documento", accessor: "document", align: "center" },
    { Header: "Nombre", accessor: "name", align: "center" },
    { Header: "Apellido", accessor: "last_name", align: "center" },
    { Header: "Estado", accessor: "state", align: "center" },
    { Header: "Género", accessor: "gender", align: "center" },
    { Header: "Ubicación Geográfica", accessor: "location", align: "center" },
    { Header: "Programa Académico", accessor: "academic_program", align: "center" },
    { Header: "Semestre", accessor: "semester", align: "center" },
    { Header: "Ultima modificación", accessor: "modified", align: "center" },
    { Header: "Acciones", accessor: "action", width: "12%", align: "center", disableSortBy: true },
  ];

  const rows = participants.map((participant) => ({
    document: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.document}
      </MDTypography>
    ),
    name: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.name}
      </MDTypography>
    ),
    last_name: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.last_name}
      </MDTypography>
    ),
    state: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.state}
      </MDTypography>
    ),
    gender: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.gender}
      </MDTypography>
    ),
    location: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.location}
      </MDTypography>
    ),
    academic_program: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.academic_program}
      </MDTypography>
    ),
    semester: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.semester}
      </MDTypography>
    ),
    modified: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {formatDate(participant.modified)}
      </MDTypography>
    ),
    action: (
      <MDBox>
        {canEdit && (
          <a onClick={() => handleOpenModal(participant)} style={{ cursor: "pointer" }}>
            <EditOutlinedIcon
              titleAccess="Editar"
              style={{
                fontSize: "24px",
                width: "24px",
                height: "24px",
                color: "#000000",
              }}
            />
          </a>
        )}
      </MDBox>
    ),
  }));

  const navigate = useNavigate();
  const location = useLocation(); // Obtener el estado de la navegación
  const { id: activityId } = useParams(); // Obtener el ID de la actividad desde la URL

  const activityTitle = location.state?.activityTitle || ""; // Recuperar el título de la actividad desde location.state

  const handleNavigateToParticipants = () => {
    navigate(`/Actividades-E/${activityId}`, {
      state: { activityTitle }, // Pasar el título como parte del estado
    });
  };

  return (
    <>
      {/* Botón "Ver participantes" encima de los otros botones */}
      {canListEvidence && (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "2vw",
            marginTop: "-80px",
            marginBottom: "10px",
          }}>
          <MDButton
            variant="outlined"
            color="info"
            onClick={handleNavigateToParticipants} // Llamar a la función para navegar
            startIcon={<VisibilityIcon />}
            endIcon={<ArrowForwardIcon />}>
            Ver evidencias de la actividad
          </MDButton>
        </MDBox>
      )}
      <MDBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px", // Espacio entre los botones
          marginRight: "2vw",
          marginTop: "30px",
          marginBottom: "20px",
        }}>
        {canCreate && (
          <>
            <MDButton onClick={handleParticipantCreate} variant="gradient" color="info">
              Crear
            </MDButton>
            <MDButton onClick={handleopenNewUpload} variant="gradient" color="info">
              Carga masiva
            </MDButton>
          </>
        )}
        <MDButton
          onClick={callHandleClickSubmit}
          data-export={true}
          variant="contained"
          color="info">
          Exportar
        </MDButton>
      </MDBox>
      <DataTable
        table={{ columns, rows }}
        isSorted={true}
        entriesPerPage={false}
        showTotalEntries={false}
        canSearch={false}
        noEndBorder={false}
      />
      {!participants || participants.length === 0 ? (
        <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography
            variant="button"
            color="secondary"
            fontWeight="regular"
            style={{ fontSize: "20px", fontFamily: "Roboto, sans-serif" }}>
            No hay datos
          </MDTypography>
        </MDBox>
      ) : (
        <>
          {/* Mostrar la paginación */}
          <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              style={{ left: "20px" }}
              variant="button"
              color="secondary"
              fontWeight="regular">
              Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
            </MDTypography>
          </MDBox>
          <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}></Pagination>
          </Stack>{" "}
        </>
      )}
      <ParticipantUpdate
        open={modalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleUpdateParticipant}
        participant={currentParticipant}
      />
    </>
  );
}

ParticipantList.propTypes = {
  handleParticipantCreate: PropTypes.func.isRequired,
  handleParticipantList: PropTypes.arrayOf(PropTypes.object),
  handleopenNewUpload: PropTypes.func.isRequired,
};
