import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/login.jpg";
import GoogleIcon from "@mui/icons-material/Google";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";
import Spinner from "components/MDSpinner";
import { usePermissions } from "context"; // Importar el contexto

function Basic() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { updatePermissions } = usePermissions(); // Obtener la función para actualizar permisos

  const handleGoogleLoginSuccess = async (response) => {
    setLoading(true);
    const { credential } = response;

    // Usar la URL del backend desde el .env
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const software_name = process.env.REACT_APP_SOFTWARE_NAME;

    // Enviar el tokenId al backend para verificar el usuario
    try {
      const res = await fetch(`${backendUrl}/users/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: credential, software_name: software_name }),
      });

      const data = await res.json();

      if (res.ok) {
        // El usuario está en la base de datos
        sessionStorage.setItem("token", data.token); // Guardar el token en sessionStorage.
        sessionStorage.setItem("software_id", data.software_id); // Guardar el token en sessionStorage.
        sessionStorage.setItem("role", JSON.stringify(data.role)); // Guardar el token en sessionStorage.
        sessionStorage.setItem("user_id", JSON.stringify(data.user)); // Guardar el token en sessionStorage.
        // Guardar los permisos en localStorage
        const permissions = JSON.stringify(data.permissions); // Convierte el array de permisos a JSON
        // Actualizar permisos en el contexto global
        updatePermissions(permissions); // Actualiza los permisos en el estado de React
        // localStorage.setItem("permissions", permissions);
        // Redirigir a /dashboard
        navigate("/bienvenida");
      } else {
        // El usuario no está en la base de datos o hubo un error
        console.error("Login failed:", data);
        // Mostrar mensaje de error si la respuesta no es exitosa
        setErrorMessage(data.message || "An error occurred during login.");
      }
    } catch (error) {
      console.error("Error verifying user:", error);
      setErrorMessage("Error verifying user: " + error.message);
    }
    setLoading(false);
  };

  const handleGoogleLoginFailure = () => {
    console.error("Google login failed");
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Spinner loading={loading}></Spinner>
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center">
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sistema de gestión de usuarios
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginFailure}
                render={(renderProps) => (
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    startIcon={<GoogleIcon />}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    Iniciar sesión con Google
                  </MDButton>
                )}
              />
            </MDBox>
          </MDBox>
        </Card>
        {errorMessage && (
          <MDBox mt={3} mb={1} textAlign="center">
            <MDAlert color="error" dismissible>
              {errorMessage}
            </MDAlert>{" "}
            {/* Mostrar el error con MDAlert */}
          </MDBox>
        )}
      </BasicLayout>
    </GoogleOAuthProvider>
  );
}

export default Basic;
