import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import Filter from "../modules/Filter";
// import { getCardTypesWithoutPagination } from "layouts/card_type/services/cardTypeService";
import { getPerFilter, getPerFilterPerFile } from "../services/filterService";
import { v4 as uuidv4 } from "uuid";
import { MenuItem } from "@mui/material";
import { CategoriesListService } from "../../../layouts/activities/services/ActivitiesServices";
// import { getStatusTypes } from "layouts/card/services/cardService";
// import { getSourceTypes } from "layouts/sources_data/services/dataSourceService";
const FilterController = forwardRef(
  (
    {
      setListData,
      dataFilter,
      filterConfig,
      dataTable,
      setTotalPages,
      setCurrentPage,
      currentPage,
      requestData,
      setError = () => {},
      setTotalData,
      setEntriesEnd,
      setEntriesStart,
      dataSourceId,
      setLoading = () => {},
      activity_id,
      handleAlert = () => {},
      viewListActivity,
    },
    ref
  ) => {
    // const filterConfig = [
    //   { visibleName: "Tipo de Carnet", dbField: "type_carnet", type: "select", source: "cardType" },
    //   { visibleName: "Estado", dbField: "status", type: "select", source: "status" },
    //   { visibleName: "Fuente", dbField: "source_type_name", type: "select", source: "sourceTypes" },
    //   { visibleName: "Documento", dbField: "number_id", type: "text" },
    //   { visibleName: "Nombre", dbField: "name", type: "text" },
    //   { visibleName: "Correo Electrónico", dbField: "email", type: "text" },
    //   { visibleName: "Fecha de Creación", dbField: "created_at", type: "date" },
    //   { visibleName: "Fecha de Actualización", dbField: "updated_at", type: "date" },
    // ];
    //Variables del Filtro  ---->
    const [dataTypeCard, setDataTypeCard] = useState();
    const [dataSourceTypes, setDataSourceTypes] = useState();
    const [categoriesList, setCategoriesList] = useState([]);
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [dataStatus, setDataStatus] = useState();
    const [dateFrom, setDateFrom] = useState("date");
    const [search, setSearch] = useState("");
    const [dateUntil, setDateUntil] = useState("date");
    const [filter, setFilter] = useState("");
    const [filterDate, setFilterDate] = useState("");
    const [filterType, setFilterType] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleDate, setIsVisibleDate] = useState(false);
    const [isVisibleType, setIsVisibleType] = useState(false);
    const [isVisibleCategory, setIsVisibleCategory] = useState(false);
    const [isVisibleSubcategory, setIsVisibleSubcategory] = useState(false);
    const [isVisibleCertifiable, setIsVisibleCertifiable] = useState(false);
    const [isVisibleSelectDateInput, setIsVisibleSelectDateInput] = useState(false);
    const [isVisibleSelectDate, setIsVisibleSelectDate] = useState(false);
    const [filters, setFilters] = useState([]);
    const [table, setTable] = useState(dataTable);
    const [ShowErrorAlert, setShowErrorAlert] = useState(true);
    const [paginaSize, setPaginaSize] = useState(10);
    // const [isSubmitted, setIsSubmitted] = useState(false);

    // Ejecutar función requestCarnetsDate con el arreglo de filtros
    const searchValidation = (currentPage, pageSize, exportExcel) => {
      if (dataSourceId) {
        requestFilterPerIdFile(dataSourceId, currentPage, pageSize, filters);
      } else {
        requestFilter(currentPage, pageSize, filters, activity_id, exportExcel);
      }
    };

    // Esperar respuesta de la función getCarnePerFilterDate con los parametros de filtro, tabla filtrad y variables del renderizado
    const requestFilter = async (pageIndex, pageSize, filters, activity_id, exportExcel) => {
      try {
        let activity_id_finally;
        if (activity_id) {
          activity_id_finally = activity_id.replace(/-/g, "");
        }
        const result = await getPerFilter(
          pageIndex,
          pageSize,
          filters,
          table,
          activity_id_finally,
          exportExcel,
          viewListActivity
        );

        const contentType = result.headers.get("Content-Type");
        if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          const blob = await result.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "export.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          handleAlert("success", "Exportación exitosa");
        } else {
          // throw new Error("The response is not a valid Excel file.");
          const resultResponse = await result.json();
          if (resultResponse && resultResponse.success !== false) {
            const { data, last_page, total, from, to } = resultResponse.payload;
            await setListData(data);
            await setTotalPages(last_page);
            if (last_page <= pageIndex) {
              await setCurrentPage(last_page);
            }
            await setTotalData(total);
            await setEntriesStart(from);
            await setEntriesEnd(to);
            setLoading(false);
            // handleAlert("success", "Filtros aplicados correctamente");
            setError("");
          } else {
            setLoading(false);
            handleAlert("fail", result.message);
          }
        }
        // setIsSubmitted(true);
      } catch (error) {
        setLoading(false);
        handleAlert("fail", error.message || "Error al procesar la respuesta del servidor");
      }
    };

    // Esperar respuesta de la función getCarnePerFilterDate con los parametros de filtro, tabla filtrad y variables del renderizado
    const requestFilterPerIdFile = async (id, pageIndex, pageSize, filters) => {
      try {
        const result = await getPerFilterPerFile(id, pageIndex, pageSize, filters, dataTable);

        if (result.status !== false) {
          const { data, last_page, total, from, to } = result;
          await setListData(data);
          await setTotalPages(last_page);
          if (last_page <= pageIndex) {
            await setCurrentPage(last_page);
          }
          await setTotalData(total);
          await setEntriesStart(from);
          await setEntriesEnd(to);
          setLoading(false);
          setError("");
        } else {
          setLoading(false);
          setError("Error al consultar al servidor");
        }
      } catch (error) {
        setLoading(false);
      }
    };

    //Controla el comportamiento de los filtros segun cual sea elegido
    const handleFilter = (event) => {
      const selectedFilter = filterConfig.find((f) => f.dbField === event.target.value);

      if (event.target.value === "") {
        setFilters([]); // Limpiamos todos los filtros
        // setSubCategoriesList([]);
        // setCategoriesList([]);
        setIsVisible(false); // Ocultamos input de texto
        setIsVisibleType(false); // Ocultamos input de tipo select
        setIsVisibleSelectDate(false); // Ocultamos inputs de fecha
        setIsVisibleSelectDateInput(false); // Asegúrate de ocultar el input de fecha
        setIsVisibleSubcategory(false); // asegurate de ocultar el select de subcategoria
        setIsVisibleCertifiable(false); // asegurate de ocultar el select de subcategoria)
        setIsVisibleCategory(false); // asegúrate de ocultar el select de categoria
        setSearch(""); // Limpiamos el valor de búsqueda
        setDateFrom("date"); // Limpiamos el campo de fecha desde
        setDateUntil("date"); // Limpiamos el campo de fecha hasta
        setFilter(""); // Limpiamos el filtro seleccionado
        return;
      }
      if (!selectedFilter) return;

      setFilter(selectedFilter.dbField);

      switch (selectedFilter.type) {
        case "text":
          setSearch("");
          setIsVisible(true);
          setIsVisibleSelectDate(false);
          setIsVisibleType(false);
          setIsVisibleSubcategory(false);
          setIsVisibleCertifiable(false);
          setIsVisibleCategory(false);
          break;
        case "select":
          setIsVisibleType(true);
          setIsVisible(false);
          setIsVisibleSelectDate(false);
          if (selectedFilter.source === "subCategoryTypes") {
            setSearch("");
            setIsVisibleSubcategory(true);
          } else {
            setIsVisibleSubcategory(false);
          }
          if (selectedFilter.source === "categoryTypes") {
            setSearch("");
            setIsVisibleCategory(true);
          } else {
            setIsVisibleCategory(false);
          }
          if (selectedFilter.source === "certifiableTypes") {
            // Nuevo caso para el filtro Certificable
            setSearch("");
            setIsVisibleCertifiable(true);
          } else {
            setIsVisibleCertifiable(false);
          }
          break;
        case "date":
          setSearch("");
          setIsVisibleSelectDate(true);
          setIsVisible(false);
          setIsVisibleType(false);
          setIsVisibleSubcategory(false);
          setIsVisibleCertifiable(false);
          setIsVisibleCategory(false);
          break;
        default:
          resetFieldValid();
          break;
      }
    };

    //Maneja y valida los filtros que se agregan, si ya existe se reemplaza el antiguo por el nuevo
    // const handleAddFilter = () => {
    //   const selectedFilter = filterConfig.find((f) => f.dbField === filter);
    //   if (!selectedFilter || (!search.trim() && filterDate === "")) {
    //     handleChangeValid("search", false);
    //     return;
    //   }

    //   const newFilter = {
    //     id: uuidv4(),
    //     type: selectedFilter.dbField,
    //     value: filter === "date" ? { from: dateFrom, until: dateUntil } : search.trim(),
    //     label: `${selectedFilter.visibleName}: ${
    //       filter === "date" ? `${dateFrom} - ${dateUntil}` : search.trim()
    //     }`,
    //   };

    //   setFilters((prevFilters) => [...prevFilters, newFilter]);
    //   //resetState();
    // };

    const handleAddFilter = () => {
      const selectedFilter = filterConfig.find((f) => f.dbField === filter);

      // Validar que se haya seleccionado un filtro
      if (!selectedFilter) {
        handleChangeValid("search", false);
        return;
      }

      // Validar si el filtro es de tipo texto o fecha
      if (selectedFilter.type === "date") {
        // Verificar que ambos campos de fecha (desde y hasta) tengan valores válidos
        if (dateFrom === "date" || dateUntil === "date" || dateFrom === "" || dateUntil === "") {
          handleChangeValid("date", false);
          return;
        }
      } else {
        // Validar que el campo de búsqueda no esté vacío para otros tipos de filtros
        if (selectedFilter.type === "select") {
          if (search.length === 0) {
            return;
          }
        } else if (typeof search === "string" && !search.trim()) {
          handleChangeValid("search", false);
          return;
        }
      }
      let num = 0;
      const newFilter = {
        id: uuidv4(),
        type: selectedFilter.dbField,
        value:
          selectedFilter.type === "date"
            ? { from: dateFrom, until: dateUntil }
            : selectedFilter.type === "select"
            ? selectedFilter.source === "subCategoryTypes"
              ? search?.map((item) => item.id)
              : selectedFilter.source === "certifiableTypes"
              ? search // Aquí guardamos directamente "1" o "0"
              : search.id
            : search.trim(),
        label: `${selectedFilter.visibleName}: ${
          selectedFilter.type === "date"
            ? `${dateFrom} - ${dateUntil}`
            : selectedFilter.type === "select"
            ? selectedFilter.source === "subCategoryTypes"
              ? search?.map((item) => item.name)
              : selectedFilter.source === "certifiableTypes"
              ? search == "0"
                ? "No"
                : "Sí"
              : search.name
            : search.trim()
        }`,
      };

      // Verificar si ya existe un filtro con el mismo tipo
      const filterExists = filters.find((f) => f.type === newFilter.type);

      if (filterExists) {
        // Si ya existe un filtro de este tipo, lo actualizamos en lugar de agregarlo
        setFilters((prevFilters) =>
          prevFilters.map((f) => (f.type === newFilter.type ? newFilter : f))
        );
      } else {
        // Si no existe, lo añadimos
        setFilters((prevFilters) => [...prevFilters, newFilter]);
      }
      // setIsSubmitted(false); // Resetea la bandera antes de la búsqueda
    };

    //Borrar filtro almacenado en el arreglo de filtros
    const handleDeleteFilter = (id) => {
      const updatedFilters = filters.filter((item) => item.id !== id);
      setFilters(updatedFilters);

      // if (updatedFilters.length === 0) {
      //   if (dataSourceId) {
      //     requestData(id, currentPage, paginaSize);
      //   } else {
      //     requestData(currentPage, paginaSize);
      //   }
      // }
    };

    // Hace llamado a la funcion que realiza la peticion de los tipos de carnets y status cuando se renderiza el componente
    useEffect(() => {
      // requestCardType();
      // requestStatus();
      // requestSourceTypes();
      requestSubCategoriesList();
    }, []);

    // Hace llamado a la funcion que valida la data con los filtros actuales cuando la pagina actual cambia

    // useEffect(() => {
    //   // if (!isSubmitted) {
    //   searchValidation(currentPage, paginaSize);
    //   // }
    // }, [currentPage, filters]);

    //Esperar respuesta de la función getCardTypes y setear  el valor a dataTypeCard
    const requestCardType = async (pageIndex) => {
      const result = await getCardTypesWithoutPagination();
      if (result && result.status !== false) {
        const { payload } = result;
        await setDataTypeCard(payload);
      } else {
      }
    };

    //Esperar respuesta de la función getCardTypes y setear  el valor a dataTypeCard
    const requestSourceTypes = async (pageIndex) => {
      const result = await getSourceTypes();
      if (result && result.status !== false) {
        const { payload } = result;
        await setDataSourceTypes(payload);
      } else {
      }
    };

    //Esperar respuesta de la función getCardTypes y setear  el valor a dataTypeCard
    const requestStatus = async (pageIndex) => {
      const result = await getStatusTypes(pageIndex);
      if (result.status !== false) {
        const { data } = result;
        await setDataStatus(data);
      } else {
      }
    };

    //Esperar respuesta de la función CategoriesListService y setear  el valor a SubCategoriesList
    const requestSubCategoriesList = async (pageIndex) => {
      setLoading(true);
      try {
        const result = await CategoriesListService(pageIndex);
        if (result.success) {
          setCategoriesList(result.payload);
          setSubCategoriesList(result.payload);
        } else {
          handleAlert("error", result.message);
        }
      } catch (error) {
        handleAlert("error", "Error al obtener las actividades");
      } finally {
        setLoading(false);
      }
    };

    //Mostrar los campos de tipo de carnets para filtrar y ocultar los campos si se selecciona "ninguno"
    const handleFilterType = (event) => {
      const selectedValue = event.target.value;
      if (selectedValue === "") {
        setSearch(selectedValue);
        handleChangeValid("search", true);
        return;
      }

      try {
        const parsedValue = JSON.parse(selectedValue);
        setSearch(parsedValue);
        handleChangeValid("search", true);
      } catch (error) {
        console.error("Error al parsear el valor seleccionado", error);
      }
    };

    //setear los valores digitados del primer filtro
    const handleChange = (event) => {
      event.preventDefault();
      handleChangeValid("search", true);
      const searchIsString = event.target.value;
      if (searchIsString.startsWith(" ")) return;
      setSearch(searchIsString.toString());
      if (filter === "") return;
    };

    //setear los cambios de fecha siempre y cuando la fecha "desde" no sea mayor a la de "hasta" y no este vacio el campo
    const handleChangeDateFrom = (event) => {
      handleChangeValid("date", true);
      if (event.target.value !== "") {
        const date = new Date(event.target.value);
        let formatDate = date.toISOString().slice(0, 10);
        if (dateUntil !== "" && dateUntil !== "date") {
          if (formatDate > dateUntil) {
            formatDate = dateUntil;
          }
        }
        setDateFrom(formatDate);
      } else {
        setDateFrom("date");
      }
    };

    //setear los cambios de fecha siempre y cuando la fecha "hasta" no sea menor a la de "desde" y no este vacio el campo
    const handleChangeDateUntil = (event) => {
      handleChangeValid("date", true);
      if (event.target.value !== "") {
        const date = new Date(event.target.value);
        let formatDate = date.toISOString().slice(0, 10);
        if (dateFrom !== "" && dateFrom !== "date") {
          if (formatDate < dateFrom) {
            formatDate = dateFrom;
          }
        }
        setDateUntil(formatDate);
      } else {
        setDateUntil("date");
      }
    };

    //Ejecutar funcion searchValidation con la página y filas para la tabla de carnets
    const handleClickSubmit = (params) => {
      if (params && typeof params === "object" && "_reactName" in params) {
        handleAddFilter();
        searchValidation(currentPage, paginaSize);
      } else {
        handleAddFilter();
        // setIsSubmitted(false); // Resetea la bandera antes de la búsqueda
        searchValidation(currentPage, paginaSize, params);
      }
    };

    // Exponer handleClickSubmit al componente padre
    useImperativeHandle(ref, () => ({
      handleClickSubmit,
    }));

    // Validaciones -->

    // Variables de validacion
    const [isFieldValid, setIsFieldValid] = useState({
      search: true,
      date: true,
    });

    // Cambia el valor de la validacion entrante
    const handleChangeValid = (fieldName, isValid) => {
      setIsFieldValid((prevValidity) => ({
        ...prevValidity,
        [fieldName]: isValid,
      }));
    };

    // Setea el estado de todas las validaciones
    const resetFieldValid = () => {
      setIsFieldValid({
        search: true,
        date: true,
      });
    };

    //Setear nuevos valores para subcategorias
    const handleFieldChange = (name, value) => {
      setSearch(value);
      if (filter === "") return;
    };

    return (
      <>
        <Filter
          dataFilter={dataFilter}
          filterConfig={filterConfig}
          filter={filter}
          handleFilter={handleFilter}
          isVisible={isVisible}
          handleChange={handleChange}
          search={search}
          isVisibleSelectDate={isVisibleSelectDate}
          filterDate={filterDate}
          handleFilterType={handleFilterType}
          filterType={filterType}
          isVisibleDate={isVisibleDate}
          isVisibleType={isVisibleType}
          isVisibleSelectDateInput={isVisibleSelectDateInput}
          isVisibleSubcategory={isVisibleSubcategory}
          isVisibleCertifiable={isVisibleCertifiable}
          isVisibleCategory={isVisibleCategory}
          dateFrom={dateFrom}
          dateUntil={dateUntil}
          handleChangeDateFrom={handleChangeDateFrom}
          handleChangeDateUntil={handleChangeDateUntil}
          handleClickSubmit={handleClickSubmit}
          dataTypeCard={dataTypeCard}
          dataStatus={dataStatus}
          dataSourceTypes={dataSourceTypes}
          handleAddFilter={handleAddFilter}
          filters={filters}
          ShowErrorAlert={ShowErrorAlert}
          handleDeleteFilter={handleDeleteFilter}
          isFieldValid={isFieldValid}
          listSubCategories={subCategoriesList}
          categoriesList={categoriesList}
          handleFieldChange={handleFieldChange}
        />
      </>
    );
  }
);
export default FilterController;
