// import fetchWrapper from "../../../fetchWrapper";

const formatDateToYYYYMMDD = (date) => {
  if (!date) return "";
  return new Date(date).toISOString().split("T")[0];
};

//Recibe el arreglo de filtros con sus respectivos valores para convertirlos en una url usada para la peticion
const getPerFilter = async (
  pageIndex,
  pageSize,
  filters,
  Table,
  activity_id,
  exportExcel,
  viewListActivity
) => {
  try {
    let url = `/${Table}/${Table}_per_filter?page=${pageIndex}&pageSize=${pageSize}`;
    if (activity_id) {
      url += `&activity_id=${activity_id}`;
    }
    if (exportExcel) {
      url += `&exportExcel=${exportExcel}`;
    }
    if (viewListActivity === 0 || viewListActivity === 1) {
      url += `&viewListActivity=${viewListActivity}`;
    }
    if (filters && Array.isArray(filters)) {
      filters.forEach((filter) => {
        if (
          filter.type !== "expires_in" &&
          filter.type !== "created" &&
          filter.type !== "created_at" &&
          filter.type !== "updated_at" &&
          filter.type !== "fecha" &&
          filter.type !== "modified"
        ) {
          {
            url += `&type_filter[]=${filter.type}&search[]=${filter.value}`;
          }
        }
        if (
          filter.type === "expires_in" ||
          filter.type === "created" ||
          filter.type === "created_at" ||
          filter.type === "updated_at" ||
          filter.type === "fecha" ||
          filter.type === "modified"
        ) {
          const fromDate = formatDateToYYYYMMDD(filter.value.from);
          const untilDate = formatDateToYYYYMMDD(filter.value.until);
          url += `&type_date[]=${filter.type}&dateFrom[]=${fromDate}&dateUntil[]=${untilDate}`;
        }
      });
    }

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/filter" + url);
    if (response.ok) {
      // const result = await response.json();

      return response;
    } else {
      throw new Error(`La respuesta del servidor no es válida: ${response.status}`);
    }

    // const { success, code } = result;
    // if (success === true) {
    //   const {
    //     payload: { data, last_page, total, from, to },
    //   } = result;
    //   return { data, last_page, total, from, to, success };
    // } else if (success === false) {
    //   const message = "Error del servidor al realizar la solicitud";
    //   const status = false;
    //   return { status, message };
    // }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

//Recibe el arreglo de filtros con sus respectivos valores para convertirlos en una url usada para la peticion
const getPerFilterPerFile = async (id, pageIndex, pageSize, filters, Table) => {
  const idFileOrDataSourceId = Table === "cards" ? "file_id" : "per_data_source";
  try {
    let url = `/${Table}/${Table}_per_filter_${idFileOrDataSourceId}/${id}?page=${pageIndex}&pageSize=${pageSize}`;
    if (filters && Array.isArray(filters)) {
      filters.forEach((filter) => {
        if (
          filter.type !== "expires_in" &&
          filter.type !== "created" &&
          filter.type !== "created_at" &&
          filter.type !== "updated_at"
        ) {
          {
            url += `&type_filter[]=${filter.type}&search[]=${filter.value}`;
          }
        }
        if (
          filter.type === "expires_in" ||
          filter.type === "created" ||
          filter.type === "created_at" ||
          filter.type === "updated_at"
        ) {
          url += `&type_date[]=${filter.type}&dateFrom[]=${filter.value.from}&dateUntil[]=${filter.value.until}`;
        }
      });
    }

    const response = await fetch(process.env.REACT_APP_API_URL + url);
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to, success };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export { getPerFilter, getPerFilterPerFile };
