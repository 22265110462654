import { useState, useEffect, useRef } from "react";
import {
  ActivitiesCreateService,
  ActivitiesListService,
  CategoriesListService,
} from "../services/ActivitiesServices";
import { ActivitiesCreate } from "../modules/ActivitiesCreate";
import ActivitiesList from "../modules/ActivitiesList";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { bool } from "prop-types";
import FilterController from "components/filter/controller/FilterController";
import data from "layouts/tables/data/authorsTableData";

export default function ActivitiesController() {
  const filterConfig = [
    { visibleName: "Título", dbField: "title", type: "text" },
    { visibleName: "Descripción", dbField: "description", type: "text" },
    { visibleName: "Palabras clave", dbField: "keywords", type: "text" },
    // { visibleName: "Formatos de evidencia", dbField: "evidence_formats", type: "text" },
    {
      visibleName: "Certificable",
      dbField: "is_certifiable",
      type: "select",
      source: "certifiableTypes",
    },
    {
      visibleName: "Subcategoría",
      dbField: "subcategory",
      type: "select",
      source: "subCategoryTypes",
    },
    {
      visibleName: "Categoría",
      dbField: "category",
      type: "select",
      source: "categoryTypes",
    },
    { visibleName: "Fecha de actividad", dbField: "fecha", type: "date" },
    { visibleName: "Documento del participante", dbField: "document", type: "text" },
    { visibleName: "Nombre del participante", dbField: "name", type: "text" },
    { visibleName: "Apellido del participante", dbField: "last_name", type: "text" },
    { visibleName: "Genero del participante", dbField: "gender", type: "text" },
    { visibleName: "Localidad del participante", dbField: "location", type: "text" },

    // { visibleName: "Categorías", dbField: "categories", type: "text" },
  ];

  const dataTable = "activities"; //Tabla indicada
  const filterControllerRef = useRef();
  const [isDisabled, setIsDisabled] = useState(false);
  const [newData, setNewData] = useState({
    title: "",
    timeStart: "",
    timeEnd: "",
    fecha: "",
    description: "",
    keywords: "",
    evidence_formats: "",
    is_certifiable: null,
    categories: "",
  });
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [handleActivitiesList, setActivitiesList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [paginaSize, setPaginaSize] = useState(10);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = (event) => {
    const { dataset } = event.target;
    const exportData = dataset.export;
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit(exportData);
    }
  };

  const requestActivitiesList = async (pageIndex, pageSize, vista) => {
    setLoading(true);
    try {
      const result = await ActivitiesListService(pageIndex, pageSize, vista);
      if (result.success) {
        const { payload } = result;
        setActivitiesList(result.payload.data);
        // setTotalPages(result.totalPages || 0);
        // setTotalData(result.totalData || 0);
        await setTotalPages(payload.last_page);
        await setTotalData(payload.total);
        await setEntriesStart(payload.from);
        await setEntriesEnd(payload.to);
      } else {
        handleAlert("error", result.message);
      }
    } catch (error) {
      handleAlert("error", "Error al obtener las actividades");
    } finally {
      setLoading(false);
    }
  };

  const requestSubCategoriesList = async (pageIndex) => {
    setLoading(true);
    try {
      const result = await CategoriesListService(pageIndex);
      if (result.success) {
        setSubCategoriesList(result.payload);
      } else {
        handleAlert("error", result.message);
      }
    } catch (error) {
      handleAlert("error", "Error al obtener las actividades");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const pageIndexAndPageSize = async (pageSize) => {
    setPaginaSize(pageSize);
    requestActivitiesList((currentPage - 1) * pageSize + 1);
  };

  const handleActivityCreate = (event) => {
    event.preventDefault();
    resetFieldValid();
    setNewData({
      title: "",
      fecha: "",
      timeStart: "",
      timeEnd: "",
      description: "",
      keywords: "",
      evidence_formats: "",
      is_certifiable: null,
      categories: "",
    });
    setOpenCreate(true);
  };

  const submitActivityCreate = async (e) => {
    e.preventDefault();
    const hasErrors = hasInvalidFields(isFieldValid);

    if (hasErrors) {
      handleAlert("fail", "Hay al menos un error en los campos ingresados");
      return;
    }

    setLoading(true);

    try {
      const startDateTime = `${newData.fecha}T${newData.timeStart}:00`;
      const endDateTime = `${newData.fecha}T${newData.timeEnd}:00`;
      const typeViewActivity = valueTab === 0 ? "agenda" : "table";

      const dataToSend = {
        ...newData,
        fecha: startDateTime,
        fecha_fin: endDateTime,
      };

      const result = await ActivitiesCreateService(dataToSend);

      if (result.success) {
        handleAlert("success", "Actividad creada con éxito.");
        await requestActivitiesList(currentPage, paginaSize, typeViewActivity);
        closeActivityCreate();
      } else {
        handleAlert("fail", result.message || "Error al crear la actividad.");
      }
    } catch (error) {
      console.error("Error durante la creación de actividad:", error);
      handleAlert("fail", "Ocurrió un error al procesar la solicitud.");
    } finally {
      setLoading(false);
    }
  };

  const closeActivityCreate = () => setOpenCreate(false);

  const validateTimeRange = (timeStart, timeEnd) => {
    if (!timeStart || !timeEnd) return true;
    return timeStart < timeEnd;
  };

  const handleFieldChange = (name, value) => {
    setNewData((prevData) => ({
      ...prevData,
      [name]: value === "" ? value : value,
    }));
    handleChangeValid(name, value);

    if (name === "timeEnd") {
      const isValid = validateTimeRange(newData.timeStart, value);
      setIsFieldValid((prev) => ({
        ...prev,
        timeEnd: isValid,
      }));
    } else if (name === "timeStart") {
      const isValid = validateTimeRange(value, newData.timeEnd);
      setIsFieldValid((prev) => ({
        ...prev,
        timeStart: isValid,
      }));
    }
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  useEffect(() => {
    const typeViewActivity = valueTab === 0 ? "agenda" : "table";
    requestActivitiesList(currentPage, 10, typeViewActivity);
    requestSubCategoriesList();
  }, [currentPage]);

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    title: true,
    fecha: true,
    timeStart: true,
    timeEnd: true,
    description: true,
    keywords: true,
    evidence_formats: true,
    is_certifiable: true,
    subcategories: true,
  });

  // Función para verificar si algún campo no es válido
  const hasInvalidFields = (fields) => {
    return Object.values(fields).some((value) => value === false);
  };

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      title: true,
      timeStart: true,
      timeEnd: true,
      fecha: true,
      description: true,
      keywords: true,
      evidence_formats: true,
      is_certifiable: true,
      subcategories: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    title: /^[a-zA-ZÀ-ÿ\s\u00f1\u00d1\-,.\d]+$/, // Solo permite letras y espacios, caracteres especiales(-,.) y números
    fecha: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/, // Solo permite letras y espacios
    description: /^[a-zA-ZÀ-ÿ\s\u00f1\u00d10-9"(),.]*$/,
    timeStart: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
    timeEnd: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
    keywords: /^[a-zA-ZÀ-ÿ\s\u00f1\u00d1,]+$/, // Solo permite letras, comas y espacios
    evidence_formats: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    is_certifiable: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    // categories: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    // const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    let isValid = true;

    if (fieldName === "subcategories") {
      // Verificar que se haya seleccionado al menos una opción
      isValid = Array.isArray(value) && value.length > 0;
    } else {
      // Otras validaciones para otros campos
      if (fieldName === "is_certifiable") {
        isValid = validateField(value, validationPatterns[fieldName]);
      } else {
        isValid = validateField(value.trim(), validationPatterns[fieldName]);
      }
    }

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isValid,
    }));
  };

  // Manejo del cambio de pestaña
  const [valueTab, setValueTab] = useState(0);

  // Cambiar de pestaña
  const handleChangeTab = async (event, newValue) => {
    setValueTab(newValue);
    if (newValue === 0) {
      await requestActivitiesList(1, 10, "agenda");
    } else {
      await requestActivitiesList(currentPage, 10, "table");
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {alert.visible && <MDAlert type={alert.type} message={alert.message} />}
      <MDAlert isAlert={isAlert} setIsAlert={setIsAlert} typeAlert={typeAlert} message={message} />
      <FilterController
        filterConfig={filterConfig}
        dataTable={dataTable}
        ref={filterControllerRef}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        setListData={setActivitiesList}
        handleAlert={handleAlert}
        viewListActivity={valueTab}></FilterController>
      <ActivitiesCreate
        open={openCreate}
        handleClose={closeActivityCreate}
        isDisabled={isDisabled}
        getFieldValue={(field) => (newData[field] !== undefined ? newData[field] : "")}
        handleFieldChange={handleFieldChange}
        handleSubmit={submitActivityCreate}
        listSubCategories={subCategoriesList}
        isFieldValid={isFieldValid}
      />
      <ActivitiesList
        handleActivitiesList={handleActivitiesList}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleActivitiesCreate={handleActivityCreate}
        callHandleClickSubmit={callHandleClickSubmit}
        valueTab={valueTab}
        handleChangeTab={handleChangeTab}
        setValueTab={setValueTab}
      />
    </>
  );
}
